import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import SEO from '../components/seo';
// images
import WhyImg1 from '../images/why1.jpg';
import WhyImg2 from '../images/why2.jpg';
import '../styles/app.scss';

const schedule = ({ data }) => {
    const { wordpressPage: post } = data;
    console.log(post);
    const test_title = post.yoast_title;
    const new_test_title = test_title.replace('&#039;', "'");
    const inlineCss = {
        maxWidth: '600px',
        marginTop: '50px',
    };

    // console.log(post.yoast_json_ld[0].wordpress__graph[0].description);

    return (
        <>
            <SEO
                title={new_test_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt centersec">
                <div className="container">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    />

                    <div className="resp-container">
                        <iframe
                            className="resp-iframe border_0"
                            src={post.acf.schedule_iframe_src}
                            width="100%"
                            height="945"
                            frameBorder="0"
                            scrolling="no"
                            title="iframe"
                        ></iframe>
                    </div>
                    <a
                        className="wrtsbtn yellowbtn healcode-register"
                        style={inlineCss}
                        href="https://clients.mindbodyonline.com/classic/home?studioid=44354"
                        target="_blank"
                    >
                        To sign up for our upcoming Classes/Special Events -
                        Click Here
                    </a>


                    <div className='div-of-iframe-canva'>
                        <iframe className='iframe-canva' loading="lazy"
                            src="https://www.canva.com/design/DAF97MQUoms/m6PTOxFLHeNy9YAWbzF8Hg/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>
                    </div>
                </div>
            </section>

            <div className="yellowfull">
                <h2 className="bluetxt">More class information</h2>
            </div>

            <section className="whywelist page-section bg-primary text-white">
                <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.story_time_title,
                            }}
                        />
                        <h4
                            dangerouslySetInnerHTML={{
                                __html: post.acf.story_time_content,
                            }}
                        />
                    </div>

                    <div className="whylistrgt flexbox">
                        <div className="equipstarimgrgt">
                            <img
                                className="whiteborderimg"
                                src={
                                    post.acf.story_time_image.localFile
                                        .childImageSharp.fluid.src
                                }
                                alt="why 1"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="whywelist page-section">
                <div className="container flexwrap whywewhitelist">
                    <div className="whylistlft flexbox todwn">
                        <div className="equipstarimglft">
                            <img
                                src={
                                    post.acf.music_classes_image.localFile
                                        .childImageSharp.fluid.src
                                }
                                alt="why 2"
                            />
                        </div>
                    </div>

                    <div className="whylistrgt flexbox whylisttxt toup">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.music_classes_title,
                            }}
                        />
                        <h4
                            dangerouslySetInnerHTML={{
                                __html: post.acf.music_classes_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default schedule;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                schedule_iframe_src
                story_time_title
                story_time_content
                story_time_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                music_classes_title
                music_classes_content
                music_classes_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
